/* GLOBAL STYLES */

*, *:before, *:after {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  body {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .App, .page {
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
  }

  .blurred {
    backdrop-filter: blur(25px);
  }

  .dark_backdrop {
    background-color: rgb(7, 4, 35, 0.8);
  }

  .light_backdrop {
    background-color: rgb(205, 205, 211, 0.8);
  }

  .no-overflow {
    overflow: hidden;
  }
  
  .show-overflow {
    overflow: visible !important;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track:hover {
    background: white;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
    border-radius: 5px;
    background: #070423;
  }
